import React from "react"
import { Link, graphql, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import Img from "gatsby-image"

import "./blog.scss"
import "./casos-reales.scss"
import ContactForm from "../components/contact-form/contact-form"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import ContactFormJotForm from "../components/contact-form/contact-form-jotform"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileContract } from "@fortawesome/free-solid-svg-icons"
export default function CasosReales({ data, pageContext }) {
  const { edges: posts } = data.allMarkdownRemark
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <React.Fragment>
      <SEO title="Casos reales - Cuéntanos tu caso" />
      <Layout pageId="casos-reales">
        <div className="blog-archive-container">
          <h1 className="page-title">Cuéntanos tu caso</h1>

          <p
            className="note"
            onClick={() => {
              navigate("/contacto")
            }}
          >
            <div className="note-left">
              <FontAwesomeIcon icon={faFileContract} color="black" size="3x" />
            </div>
            <div className="note-right">
              Si no encuentras tu caso nos lo puedes contar en el{" "}
              <strong>
                <Link to="/contacto/">formulario de contacto</Link>
              </strong>
            </div>
          </p>

          {/* <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton></AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ContactForm title="Cuéntanos tu caso" />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion> */}

          <div className="blog-posts">
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }) => {
                // let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;

                return (
                  <div className="blog-post-preview" key={post.id}>
                    <div className="header">
                      {post.frontmatter.featuredImage && (
                        <Img
                          className="header-left"
                          fluid={
                            post.frontmatter.featuredImage.childImageSharp.fluid
                          }
                        />
                      )}
                      <div className="header-right">
                        <Link to={`${post.frontmatter.path}/`}>
                          <span className="title">
                            {post.frontmatter.title}
                          </span>
                        </Link>
                        <h2 className="date">{post.frontmatter.date}</h2>
                      </div>
                    </div>

                    <p className="content">{post.excerpt}</p>
                    <Link className="seguir" to={`${post.frontmatter.path}/`}>
                      <i>Seguir leyendo</i>
                    </Link>
                  </div>
                )
              })}
          </div>
          <div className="pagination-controls">
            {!isFirst && (
              <Link to={`/blog/${prevPage}`} rel="prev">
                ← Anteriores
              </Link>
            )}
            {isFirst && <span>← Anteriores</span>}
            {!isLast && (
              <Link to={`/blog/${nextPage}`} rel="next">
                Siguientes →
              </Link>
            )}
            {isLast && <span>Siguientes →</span>}

            {/* {Array.from({ length: numPages }, (_, i) => (
                            <Link
                                key={`pagination-number${i + 1}`}
                                to={`/${i === 0 ? "" : i + 1}`}
                            >
                                Página {i + 1}
                            </Link>
                        ))} */}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export const pageQuery2 = graphql`
  query CasosRealesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/casos-reales/" } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY", locale: "es")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
